.theme-dark {

    $darkblue:      #1e1e2c;
    $grey:          #141414;
    $lightgrey:     #292929;
    $black:         #000;
    $primary:       #6B9BFF;
    $secondary:     #3700B3;
    $tertiary:      #03dac6;
    $quaternary:    #BB86FC;
    $warning:       #c97764;
    $danger:        #cf6679;
    $white:         rgb(173, 181, 189);
    $pale:          rgba(173, 181, 189, 0.87);
    $disabled:      rgba(173, 181, 189, 0.38);

    a {
      color: inherit !important;
    }

    .toggle-switch .slider{
      background-color: #3700B3 !important;
      box-shadow: 0 0 1px #3700B3 !important;
    }

    a:hover{
        color: inherit !important;
    }
    body {
      background-color:  $darkblue !important;
      color: $white !important;
    }
    
    .table-striped > tbody > tr:nth-of-type(odd),
    .text-gray,
    .form-control::placeholder,
    .form-control-select__placeholder {
      color: $pale !important;
    }
    .card {
      background-color:  $grey !important;
      color: $pale !important;
    }
    
    h6,
    .h6,
    h5,
    .h5,
    h4,
    .h4,
    h3,
    .h3,
    h2,
    .h2,
    h1,
    .h1{
      color: $white ;
    }
    
    table,
    .table,
    .tableFixHead thead th,
    .table .thead-light th {
      color: $pale !important;
      background-color: $grey !important;
    }

    .text-dark,
    .text-title,
    label{
      color: $white !important;
    }
    
    .bg-light,
    .text-dark a:not(.btn) {
      color: $white !important;
    }
    .bg-lightblue,
    .text-light {
      color: $grey !important;
    }
    
    .preloader {
      background-color: $grey !important;
    }
    
    .table-hover > tbody > tr:hover,
    .list-group-item
     {
        color: $pale;
        background-color: $grey;
    }

    .form-check.border-chk input[type=checkbox]:checked + label,
    .form-control-select__multi-value{
      background-color: $primary;
      color: $black !important;
    }
    .form-control-select__control{
      background-color: transparent;
      border-color: transparent;
    }
    
    .form-control,
    .form-control-select__menu,
    .form-control-select__control,
    .filterSearch{
      background-color: $lightgrey !important;
      color: $white !important;
      border-color: $grey !important;
    }

    .page-drop-down *{
      border-color: transparent !important;
    }
    .language-toggle-section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    
    .language-dropdown {
      width: 200px;
      margin-right: 15px;
      font-size: 14px;
    }
    
    .language-option {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    
    .toggle-button {
      margin-left: 10px; /* Adjust as needed to position next to dropdown */
    }
    
    .dropdown-menu .dropdown-item:hover,
    .dropdown-menu .dropdown-item:focus,
    .modal-content,
    .modal-body{
      background-color: $grey !important;
      color: $pale !important;
      border-color: $grey !important;
    }
    .page-link,
    .dashboard-dropdown,
    .dropdown-menu-left,
    .dropdown-menu{
      background-color: $lightgrey !important;
      color: $white !important;
      border-color: $grey !important;
    }
    .modal-header,
    .modal-header .btn-close,
    .btn-close {
      color: $white !important;
    }
    
    input,
    .form-control-select__single-value{
      color: $white !important;
    }
    .form-control-select__menu,
    .form-control-select__menu *,
    .form-control-select__control{
      background-color: $lightgrey !important;
      color: $white !important;
    }
    .page-drop-down *,
    .language-dropdown *,
    .page-link *,
    .dashboard-dropdown *,
    .dropdown-menu-left,
    .dropdown-menu {
      background-color: $lightgrey !important;
      color: $white !important;
    }
    
    .form-control-select__menu > div  *:hover{
      background-color: $black !important;
    }
    //Sidebar
    .sidebar {
      background-color: $grey !important;
      
      .nav-item .nav-link {
        background-color: $grey !important;
        color: $primary !important;
      }
    }
    
    .card,
    .card-header {  
        h6,
        .h6,
        h5,
        .h5,
        h4,
        .h4,
        h3,
        .h3,
        h2,
        .h2,
        h1,
        .h1{
          color: inherit;
        }
    }
    //Buttons and backgrounds
    .disabled{
      color: $disabled;
    }
    .bg-white, .bg-light, .btn-light
    {
        background-color: $grey !important;
              color: $pale  !important;;

            h6,
            .h6,
            h5,
            .h5,
            h4,
            .h4,
            h3,
            .h3,
            h2,
            .h2,
            h1,
            .h1{
              color: $white  !important;;
            }
      }

    .bg-soft-indigo,
    .bg-light,
    .btn-light {
      background-color: $lightgrey !important;
      border-color: $grey !important;
      color: $pale !important;
    }
    
    .bg-dark,
    .btn-outline-dark {
      border-color: inherit!important;
      color: inherit !important;
    }
    
    
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link,
    .bg-secondary,
    .btn-secondary {
      background-color: $secondary !important;
      border-color: $secondary !important;
      color: $white !important;
    }
    
    .bg-primary,
    .btn-primary {
      background-color: $primary !important;
      border-color: $primary !important;
      color: $black !important;
    }
    .bg-lightblue{
      background-color:#36A799!important;
      border-color: #36A799 !important;
      color: $black !important;
    }
    .bg-tertiary,
    .btn-tertiary {
      background-color:$tertiary!important;
      border-color: $tertiary !important;
      color: $black !important;
    }
    
    .bg-success,
    .btn-success {
      background-color: $tertiary !important;
      border-color: $tertiary !important;
      color: $black !important;
    }
    
    .btn-warning,
    .bg-warning,
    .alert-warning {
      background-color: $warning !important;
      border-color: $warning !important;
      color: $black !important;
    }
    
    .btn-danger,
    .bg-danger,
    .alert-danger {
      background-color: $danger !important;
      border-color: $danger !important;
      color: $black !important;
    }
    .breadcrumb-item,
    .breadcrumb-item a.breadcrumb-item,
    .breadcrumb-primary.breadcrumb-transparent .breadcrumb-item.active {
      color: $primary;
    }
  }
  
  .btn.bg-transparent,
  .bg-transparent{
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  /* Custom styles for the Toast notification in dark mode */
.toast-container-dark {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050;
}

.toast-dark {
  background-color: #292929; /* $lightgrey */
  color: #fff; /* $white */
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.toast-header-dark {
  background-color: #1e1e2c; /* $darkblue */
  color: #fff; /* $white */
  border-bottom: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.toast-body-dark {
  color: #fff; /* $white */
}