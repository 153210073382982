/**
 * = Timelines
 */

.list-group-timeline {
    .list-group-item {
        position: relative;
        .col-auto{
            z-index: 999;
        }
        &:not(:last-child){
            &::before {
                content: '';
                position: absolute;
                top: 2.75rem;
                left: 0.45rem;
                height: 100%;
                border-left: 1px solid $light;
            }
        }
    }
}

